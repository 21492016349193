import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";

import Header2 from "./../../Layout/Header2";
import Footer2 from "./../../Layout/Footer2";
import SideNav from "./../../Element/SideNav";
import VideoPopup from "./../../Element/VideoPopup";

import Slider1 from "./../../Element/Slider1";
import Mapview from "./../../Element/Mapview";
import EventSection from "./EventSection";
import SpecificationsBlog from "./SpecificationsBlog";
import SitePlanTab from "./SitePlanTab";

import SliderImg from "./../../../images/main-slider/gate-1.png";
import main1 from "./../../../images/gallery/main1.jpg";

import spec1 from "./../../../images/specifications/pic1.jpg";
import spec2 from "./../../../images/specifications/pic2.jpg";
import spec3 from "./../../../images/specifications/pic3.jpg";

import main2 from "./../../../images/gallery/main2.jpg";
import main3 from "./../../../images/gallery/main3.jpg";
import main4 from "./../../../images/gallery/main4.jpg";
import main5 from "./../../../images/gallery/main5.jpg";
import main6 from "./../../../images/gallery/main6.jpg";
import "animate.css";

import gallery1 from "./../../../images/gallery/pic1.jpg";
import gallery2 from "./../../../images/gallery/pic2.jpg";
import gallery3 from "./../../../images/gallery/pic3.jpg";
import gallery4 from "./../../../images/gallery/pic4.jpg";
import gallery5 from "./../../../images/gallery/pic5.jpg";
import gallery6 from "./../../../images/gallery/pic6.jpg";

import services1 from "./../../../images/services/pic1.jpg";
import services2 from "./../../../images/services/pic2.jpg";
import services3 from "./../../../images/services/pic3.jpg";
import services4 from "./../../../images/services/pic4.jpg";
import services5 from "./../../../images/services/pic5.jpg";
import services6 from "./../../../images/services/pic6.jpg";
import services7 from "./../../../images/services/pic7.jpg";
import services8 from "./../../../images/services/pic8.jpg";
import services9 from "./../../../images/services/pic9.jpg";
import services10 from "./../../../images/services/pic10.jpg";

class Index1 extends React.Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  render() {
    return (
      <Fragment>
        <div className="sidenav-list">
          <SideNav />
        </div>

        <Header2 />
        {/* <!-- Main Slider --> */}
        <div className="page-content bg-white">
          <div
            className="banner-three"
            style={{
              backgroundImage: "url(" + SliderImg + ")",
              backgroundSize: "cover",
              position: "relative",
            }}
            data-content="HOME"
            id="sidenav_home"
          >
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: "1",
              }}
            ></div>

            <div
              className="container"
              style={{ position: "relative", zIndex: "2" }}
            >
              <div className="row align-items-center banner-inner">
                <div className="col-md-6">
                  <div className="content-blog">
                    <div className="banner-content">
                      <h1
                        className="wow title fadeInUp m-b20 text-white"
                        data-wow-delay="1s"
                        data-wow-duration="3s"
                      >
                        Growing Future Generations
                        <br />
                        Through Thoughtful
                        <br />
                        Development
                      </h1>
                      <Link
                        to={"/contact-us-1"}
                        className="wow fadeInUp btn btn-primary bg-golden"
                        data-wow-delay="2s"
                        data-wow-duration="3s"
                      >
                        Book A Site Visit
                      </Link>
                    </div>
                    {/* <VideoPopup /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Main Slider End--> */}

          {/* Section-1  Start*/}
          <section className="amenities-area">
            <Slider1 />
          </section>
          {/* Section-1  End*/}

          {/* Section-2  Start*/}
          {/* <section
            className="content-inner-2"
            data-content="SPECIFICATIONS"
            id="sidenav_specifications"
          >
            <div className="container">
            <div className="section-head head-col">
     <h2
       className="title wow fadeInUp"
       data-wow-duration="2s"
       data-wow-delay="0.3s"
     >
       Specifications
     </h2>
     <p
       className="wow fadeInUp"
       data-wow-duration="2s"
       data-wow-delay="0.6s"
     >
       There are many variations of passages of Lorem Ipsum available, but the
       majority have suffered alteration in some form
     </p>
   </div>

            </div>
            <div className="row faqs-box spno">
			<div className="col-md-8 col-lg-8 col-xl-8">
  <div
    className="faq-media wow fadeInLeft"
    data-wow-duration="2s"
    data-wow-delay="0.6s"
  >
    <Link to={"#"}>
      <img src={spec1} id="Capmap1" className="active" alt="" />
    </Link>
    <Link to={"#"}>
      <img src={spec2} id="Capmap2" alt="" />
    </Link>
    <Link to={"#"}>
      <img src={spec3} id="Capmap3" alt="" />
    </Link>
  </div>
</div>

              <SpecificationsBlog />
            </div>
          </section> */}
          {/* Section-2 End */}

          {/* Section-3 */}

          <section
            className="content-inner about-box"
            data-content="ABOUT US"
            id="sidenav_aboutUs"
          >
            <div className="about-bg"></div>
            <div className="container">
              <div className="row">
                <div className="col-md-7 col-lg-6">
                  <div className="section-head">
                    <h2
                      className="title wow fadeInUp"
                      data-wow-duration="2s"
                      data-wow-delay="0.2s"
                    >
                      Projects Overview
                    </h2>
                    <div
                      className="dlab-separator bg-primary wow fadeInUp"
                      data-wow-duration="4s"
                      data-wow-delay="0.2s"
                    ></div>
                    <h4
                      className="mb-4 wow fadeInUp"
                      data-wow-duration="2s"
                      data-wow-delay="0.6s"
                    >
                      DISCOVER MORE THAN A HOME – DISCOVER A LIFESTYLE
                    </h4>
                    <p
                      className="wow fadeInUp"
                      data-wow-duration="2s"
                      data-wow-delay="0.8s"
                    >
                      Canal Palms Phase 4 embodies luxury living, offering
                      Sargodha a meticulously curated environment where
                      lifestyle transcends conventional boundaries.
                    </p>
                    <p
                      className="wow fadeInUp"
                      data-wow-duration="2s"
                      data-wow-delay="1s"
                    >
                      This development integrates premium amenities, from
                      distinguished co-working spaces and elite sports academies
                      to refined wellness zones and thoughtfully designed
                      culinary spaces. Every element is crafted to enhance
                      residents' daily lives, fostering an atmosphere of
                      community and elegance. With a profound commitment to
                      future generations, Canal Palms Phase 4 dedicates unique
                      facilities to children’s growth, education, and
                      recreation, promising an elevated lifestyle and enduring
                      legacy for all who call it home
                    </p>
                  </div>
                  <Link
                    to={"/about-us-1"}
                    className="btn btn-primary wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="1.2s"
                  >
                    About Us
                  </Link>
                </div>

                <div className="col-md-5 col-lg-6"></div>
              </div>
            </div>
          </section>
          {/* Section-3 End*/}
          {/* Section-4 Start */}
          <section
            className="content-inner"
            data-content="MASTER PLAN"
            id="sidenav_masterPlan"
          >
            <SitePlanTab />
          </section>
          {/* Section-4 End*/}
          {/* Section-5 Start*/}
          <section id="sidenav_mainGallery">
            <div
              className="main-gallery wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.5s"
            >
              <div className="gallery-img">
                <img src={main1} id="structureOne" className="active" alt="" />
                <img src={main2} id="structureTow" alt="" />
                <img src={main3} id="kitchenOne" alt="" />
                <img src={main4} id="kitchenTow" alt="" />
                <img src={main5} id="kitchenThree" alt="" />
                <img src={main6} id="interiorOne" alt="" />
              </div>
              <div className="gallery-area">
                <div className="gallery-category">
                  <div className="category-box">
                    <div className="media">
                      <img src={gallery1} alt="" />
                    </div>
                    <div className="info">
                      <h3 className="title">Structure</h3>
                      <span>
                        <i className="las la-image"></i> 02
                      </span>
                    </div>
                  </div>
                  <div className="category-media">
                    <div className="items" data-image-bx="structureOne">
                      <div className="media">
                        <img src={gallery1} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="structureTow">
                      <div className="media">
                        <img src={gallery2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery-category">
                  <div className="category-box">
                    <div className="media">
                      <img src={gallery1} alt="" />
                    </div>
                    <div className="info">
                      <h3 className="title">Kitchen</h3>
                      <span>
                        <i className="las la-image"></i> 03
                      </span>
                    </div>
                  </div>
                  <div className="category-media">
                    <div className="items" data-image-bx="kitchenOne">
                      <div className="media">
                        <img src={gallery3} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="kitchenTow">
                      <div className="media">
                        <img src={gallery4} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="kitchenThree">
                      <div className="media">
                        <img src={gallery5} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery-category">
                  <div className="category-box">
                    <div className="media">
                      <img src={gallery3} alt="" />
                    </div>
                    <div className="info">
                      <h3 className="title">Interior Finish</h3>
                      <span>
                        <i className="las la-image"></i> 01
                      </span>
                    </div>
                  </div>
                  <div className="category-media">
                    <div className="items" data-image-bx="interiorOne">
                      <div className="media">
                        <img src={gallery6} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery-category">
                  <div className="category-box">
                    <div className="media">
                      <img src={gallery4} alt="" />
                    </div>
                    <div className="info">
                      <h3 className="title">bedroom interior</h3>
                      <span>
                        <i className="las la-image"></i> 04
                      </span>
                    </div>
                  </div>
                  <div className="category-media">
                    <div className="items" data-image-bx="interiorOne">
                      <div className="media">
                        <img src={gallery6} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="kitchenOne">
                      <div className="media">
                        <img src={gallery3} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="kitchenTow">
                      <div className="media">
                        <img src={gallery4} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="interiorOne">
                      <div className="media">
                        <img src={gallery6} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gallery-category">
                  <div className="category-box">
                    <div className="media">
                      <img src={gallery5} alt="" />
                    </div>
                    <div className="info">
                      <h3 className="title">Window</h3>
                      <span>
                        <i className="las la-image"></i> 02
                      </span>
                    </div>
                  </div>
                  <div className="category-media">
                    <div className="items" data-image-bx="interiorOne">
                      <div className="media">
                        <img src={gallery6} alt="" />
                      </div>
                    </div>
                    <div className="items" data-image-bx="kitchenTow">
                      <div className="media">
                        <img src={gallery4} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Section-5 End*/}
          {/* Section-6 Start*/}
          <section
            className="content-inner-2"
            data-content="OUR SERVICES"
            id="sidenav_ourServices"
          >
            <div className="container">
              <div
                className="row align-items-end section-head wow fadeInUp"
                data-wow-duration="2s"
                data-wow-delay="0.5s"
              >
                <div className="col-md-6">
                  <h2 className="title">Building & Services</h2>
                  <div className="dlab-separator bg-primary"></div>
                  <p className="mb-3 mb-md-0">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form
                  </p>
                </div>
                <div className="col-md-6 text-md-right">
                  <Link to={"./blog-grid"} className="btn btn-primary">
                    View All Services
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="service-area"
              style={{ backgroundImage: "url(" + main1 + ")" }}
            >
              <div className="row spno service-row">
                <div
                  className="col wow bounceInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0.2s"
                >
                  <div className="service-box">
                    <div className="media">
                      <img src={services1} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">24 HRS. Water Supply</h4>
                      </Link>
                      <p>
                        The KingArchitect is a collection of grand proportioned.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col wow bounceInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0.4s"
                >
                  <div className="service-box">
                    <div className="media">
                      <img src={services2} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">CCTV With Intercom</h4>
                      </Link>
                      <p>
                        The KingArchitect is a collection of grand proportioned.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col wow bounceInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0.6s"
                >
                  <div className="service-box">
                    <div className="media">
                      <img src={services3} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">Power Backup</h4>
                      </Link>
                      <p>
                        The KingArchitect is a collection of grand proportioned.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col wow bounceInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0.8s"
                >
                  <div className="service-box">
                    <div className="media">
                      <img src={services4} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">DTH Facilitiy</h4>
                      </Link>
                      <p>
                        The KingArchitect is a collection of grand proportioned.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col wow bounceInUp"
                  data-wow-duration="2s"
                  data-wow-delay="1s"
                >
                  <div className="service-box">
                    <div className="media">
                      <img src={services5} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">Rain Water Harvesting</h4>
                      </Link>
                      <p>
                        The KingArchitect is a collection of grand proportioned.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col wow bounceInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0.2s"
                >
                  <div className="service-box">
                    <div className="media">
                      <img src={services6} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">Fully Automatic Lifts</h4>
                      </Link>
                      <p>
                        The KingArchitect is a collection of grand proportioned.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col wow bounceInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0.4s"
                >
                  <div className="service-box">
                    <div className="media">
                      <img src={services7} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">Allotted Parking</h4>
                      </Link>
                      <p>
                        The KingArchitect is a collection of grand proportioned.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col wow bounceInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0.6s"
                >
                  <div className="service-box">
                    <div className="media">
                      <img src={services8} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">Fire Fighting System</h4>
                      </Link>
                      <p>
                        The KingArchitect is a collection of grand proportioned.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col wow bounceInUp"
                  data-wow-duration="2s"
                  data-wow-delay="0.8s"
                >
                  <div className="service-box">
                    <div className="media">
                      <img src={services9} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">Security</h4>
                      </Link>
                      <p>
                        The KingArchitect is a collection of grand proportioned.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col wow bounceInUp"
                  data-wow-duration="2s"
                  data-wow-delay="1s"
                >
                  <div className="service-box">
                    <div className="media">
                      <img src={services10} alt="" />
                    </div>
                    <div className="info">
                      <Link to={"./blog-details"}>
                        <h4 className="title">Tow Level Parking</h4>
                      </Link>
                      <p>
                        The KingArchitect is a collection of grand proportioned.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Section-6 End*/}
          {/* Section-7 Start*/}
          <section
            className="content-inner-2 mt-4"
            data-content="SITE MAP"
            id="sidenav_newsEvent"
          >
            <EventSection />
          </section>
          {/* Section-7 End*/}

          {/* <Mapview /> */}
        </div>
        <Footer2 />
      </Fragment>
    );
  }
}

export default Index1;
