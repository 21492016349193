import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import pic1 from "./../../images/amenities/001.jpg";
import pic2 from "./../../images/amenities/002.jpg";
import pic3 from "./../../images/amenities/003.jpg";
import pic4 from "./../../images/amenities/004.jpg";

const latestBlog = [
  { image: pic1 },
  { image: pic2 },
  { image: pic3 },
  { image: pic4 },
];
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next la la-angle-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div
        className=" owl-prev la la-angle-left"
        onClick={onClick}
        style={{ zIndex: 1 }}
      />
    </div>
  );
}
class Slider1 extends Component {
  render() {
    var settings = {
      arrows: true,
      centerMode: true,
      centerPadding: "500px",
      slidesToShow: 1,
      speed: 500,
      navSpeed: 500,
      infinite: true,
      autoplay: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 1,
            centerPadding: "400px",
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            centerPadding: "250px",
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            centerPadding: "250px",
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerPadding: "200px",
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            centerPadding: "90px",
          },
        },
      ],
    };
    return (
      <Slider
        className="amenities-carousel owl-carousel owl-btn-center-lr "
        {...settings}
      >
        {latestBlog.map((item, index) => (
          <div   className="items wow fadeInUp"
          key={index}
          data-wow-duration="2s"
          data-wow-delay={`${index * 0.2}s`}
          style={{ width: "100%", display: "inline-block" }}>
            <div className="amenit-box">
              <div className="media">
                <Link to={"#"} style={{ width: "100%" }}>
                  <img src={item.image} alt="" style={{ width: "100%" }} />
                </Link>
              </div>
              <div className="info">
                <h5 className="title">
                  <i className="ti-home"></i>
                  <span>
                    <Link to={"./project-detail-1"}>Club House</Link>
                  </span>
                </h5>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    );
  }
}

export default Slider1;
