import React,{Component, Fragment} from 'react';
import {Link} from 'react-router-dom';

import logo1 from './../../images/logo/Trangoforce Logo.png';
// import logo2 from './../../images/logo/logo2.png';
// import logo3 from './../../images/logo/logo3.png';
import logo4 from './../../images/logo/Trango Developers Logo (White).png';
import logowhite from './../../images/logo-white.png';

class Footer extends Component{
	render(){
		return(
			<Fragment>
				<footer className="site-footer" id="sidenav_footer">
				<div className="footer-top">
					<div className="container">
						<div className="row">
							<div className="col-md-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
								<Footercol1 />
							</div>
							<div className="col-md-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.6s">
								<Footercol2 />
							</div>
							<div className="col-md-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.9s">
								<Footercol3 />
							</div>
						</div>
					</div>
				</div>
				{/*  footer bottom part */}
				<div className="footer-bottom">
					<Footerbottom />
				</div>
			</footer>
			</Fragment>
		)
	}
}
function Footercol1(){
	return(
		<>
			<div className="widget widget_about">
				<div className="footer-logo">
					<Link to="./"><img src={logowhite} alt=""/></Link> 
				</div>
				<p>Surround yourself with fresh energy, high-tech amenities, and elevated style. Indulge in extraordinary amenities, relax in appealing social spaces.</p>
				<div className="dlab-social-icon">
					<ul>
						<li><Link to={"#"} className="fa fa-facebook mr-1"  ></Link></li>
						<li><Link to={"#"} className="fa fa-twitter mr-1"   ></Link></li>
						<li><Link to={"#"} className="fa fa-linkedin mr-1"  ></Link></li>
						<li><Link to={"#"} className="fa fa-instagram" ></Link></li>
					</ul>
				</div>
			</div>
		</>
	)
} 

function Footercol2(){
	return(
		<>
			<div className="widget">
				<h5 className="footer-title">Contact Us</h5>
				<ul className="contact-info-bx">
					<li><i className="las la-map-marker"></i><strong>Address</strong> 1st Floor, 123 West Street, Melbourne Victoria 3000 Australia</li>
					<li><i className="las la-phone-volume"></i><strong>Call :-</strong>    0123-123456</li>
					<li><i className="las la-phone-square-alt"></i><strong>Board line :-</strong> 0123-4567890</li>
				</ul>
			</div>
		</>
	)
} 

function Footercol3(){
	return(
		<>
			<div className="widget widget-logo">
			   <h5 className="footer-title">Our Business Channels</h5>
			   <ul>
					<li className='pb-0'><Link to={"#"}><img src={logo1} alt=""/></Link></li>
					<li></li>
					<li><Link to={"#"}><img src={logo4} alt=""/></Link></li>
					<li></li>
			   </ul>
			</div>
		</>
	)
} 

function Footerbottom(){
	return(	
		<>
			<div className="container">
				<div className="row">
					<div className="col-md-6 col-sm-12 text-md-left text-center"> <span>© 2021 DexignZone. All Right Reserved</span> </div>
					<div className="col-md-6 col-sm-12 text-md-right text-center"> 
						<div className="widget-link "> 
							<ul>
								<li><Link to={"/about-us-1"}> About</Link></li>
								<li><Link to={"/contact-us-1"}> Contact Us</Link></li>
								<li><Link to={"/privacy-policy"}> Privacy Policy</Link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	)	
}

export {Footercol1, Footercol2, Footercol3 , Footerbottom }; 
export default Footer;